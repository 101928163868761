import React, { useCallback, useEffect, useState } from 'react';
import { ServiceCard, ServiceDetailsCard } from '../../components/serviceCard';
import * as Types from '../../globalTypes';
import * as Icons from '../../iconLibrary/svgIconLibrary';
import '../../style_sheets/nsec.scss';
import { getPublicKey } from 'nostr-tools';
import { hexToBytes } from '@noble/hashes/utils'
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import client from '../../client';
import { useDispatch } from '../../redux';
import { updateSession } from '../../redux/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const serviceCards: Types.ServiceCardProps[] = [
	{
		id: 0,
		icon: Icons.cloud(),
		name: 'Basic',
		price: 'Free',
		description: ['Single Nostr Identity', 'Email Authentication', 'For Verified Apps']
	},
	{
		id: 1,
		icon: Icons.cloud(),
		name: 'Pro',
		price: '21k sats',
		description: ['Up to 3 Nostr Identities', 'MFA/2FA Authentication', 'For Any Compatible App', 'Priority Support & Early Features', 'Invites for 2 Extra Users'],
		status: 'Coming Soon'
	},
	{
		id: 2,
		icon: Icons.cloud(),
		name: 'Team',
		price: '210k sats',
		description: ['Up to 3 Nostr Identities per User', 'Identity Sharing / Delegation', 'MFA/2FA Authentication', 'For Any Compatible App', 'Priority Support & Early Features', 'Invites for 10 Extra Users'],
		status: 'Coming Soon'
	},
	{
		id: 3,
		icon: Icons.home(),
		name: 'Sovereign',
		price: '61.5k sats',
		description: ['Connect a Self-Hosted Signer', 'for up to 10 Nostr Identities', 'Nostr-only Authentication', 'For Any Compatible App', 'Priority Support & Early Features'],
		status: 'Coming Soon'
	}
]

const Nsec: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [selectedService, setSelectedService] = useState<Types.ServiceCardProps | string>('');
	const [nsec, setNsec] = useState("");

	const [requestToken, setRequestToken] = useState<string | undefined>();
	const [isLogin, setIsLogin] = useState<boolean | null>(null);


	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const r = urlParams.get("requestToken");

		setRequestToken(r || undefined)


	}, [location]);




	const selectService = (card: Types.ServiceCardProps) => {
		setSelectedService(card);
	}


	const continueWithNsec = useCallback(async () => {
		if (!nsec) {
			return toast.error(<Toast title="Error" message="Input your nsec first" />);
		}
		try {
			getPublicKey(hexToBytes(nsec))
		} catch {
			return toast.error(<Toast title="Error" message="Not a valid Nostr private key" />);
		}
		const loginRes = await client.LoginWithNsec({ nostr_secret: nsec });
		if (loginRes.status !== "OK") {
			if (loginRes.reason !== "User doesn't exist") {
				return toast.error(<Toast title='Error' message={loginRes.reason} />);
			} else {
				const signUpRes = await client.SignUpWithNsec({ nostr_secret: nsec, request_token: requestToken });
				if (signUpRes.status !== "OK") {
					return toast.error(<Toast title='Error' message={signUpRes.reason} />);
				}
				dispatch(updateSession(signUpRes.session_token))
				toast.success(<Toast title='Success' message="Signed up successfuly" />);
				setIsLogin(false)

			}
		} else {
			dispatch(updateSession(loginRes.session_token))
			toast.success(<Toast title='Success' message="Logged in successfuly" />);
			setIsLogin(true)
		}
	}, [nsec, dispatch, requestToken])

	useEffect(() => {
		if (isLogin === null) return;
		if (isLogin && requestToken) {
			navigate('/confirmation', {
				state: {
					requestToken,
				}
			});
		}
	}, [isLogin, navigate])


	return (
		<div className='container'>
			<div className='nsec'>
				<div className='page-title'>Log-In with NSEC</div>
				{
					selectedService === ''
						?
						<>
							<div className='page-description'>
								<div>
									Sanctum offers more capable and secure remote signing for Nostr, without the need for a browser extension.
								</div><br />
								<div className='input'>
									<div>Import a private key:</div>
									<input type='password' value={nsec} placeholder='Enter NSEC' onChange={e => setNsec(e.target.value)} />
									<div className='small'>* To self-host a signer without providing an nsec, choose sovereign below.</div>
								</div>
							</div>

							<div className='title content-title'>Choose a service level</div>
							<div className='subtitle'>Tap to select and show details</div>
						</>
						:
						<ServiceDetailsCard selectedService={selectedService} close={() => setSelectedService('')} />
				}

				<div className='choose-service'>
					<div className='service-card-group'>
						{serviceCards.map((card, i) => <ServiceCard key={i} {...card} selectService={() => selectService(card)} />)}
					</div>
					<div className='tips marked'>Subscription prices are one-time, for life!</div>
				</div>

				<div className='continue-btn'>
					<button onClick={continueWithNsec}>Continue</button>
				</div>
			</div>
		</div>
	);
}

export default Nsec;
